import React, {useEffect, useRef, useState} from "react";
import WidgetExecutionOutput from "../../models/WidgetExecutionOutput";
import {Button} from "antd";
import Section from "./Types/Section";
import OutputTable from "./Types/OutputTable";
import PropertyOutputTable from "./Types/PropertyOutputTable";
import WidgetError from "./Types/WidgetError";
import {CloseOutlined, CopyOutlined} from "@ant-design/icons/lib";
import {WidgetListItemWrapper} from "../Widgets/WidgetListItem";
import GlobalComponentContext from "../../models/GlobalComponentContext";
import * as htmlToImage from 'html-to-image';

interface Context extends GlobalComponentContext {
    run: WidgetExecutionOutput
    index: number
}

const WidgetOutputItem = ({run, index, globalState, setGlobalState}: Context) => {

    const ref = useRef<any>();

    const [hideButtons, setHideButtons] = useState(false);

    const copyOutputImg = () => {
        if (ref) {
            setHideButtons(true);
            setTimeout(() => {
                htmlToImage.toBlob(ref.current)
                    .then((imgBlob) => {
                        if (imgBlob) {
                            navigator.clipboard.write([
                                new ClipboardItem({
                                    // @ts-ignore
                                    'image/png': imgBlob // change image type accordingly
                                })
                            ]).then(() => {
                                setHideButtons(false)
                            })
                                .catch(() => {
                                    setHideButtons(false)
                                });
                        }
                    });
            }, 100);

        }

    }

    const getTextFromSection = (section: WidgetExecutionOutput, text: string) => {
        if (section.children && section.children.length > 0) {
            text += section.props.name + '\n';
            section.children.forEach(cc => {
                if (cc.type === "Section") {
                    text += getTextFromSection(cc, '\n');
                    return;
                }
                if (cc.props && cc.props.properties) {
                    Object.keys(cc.props.properties).forEach(key => {
                        text += `${key}, ${cc.props.properties[key]} \n`
                    });
                }
                if (cc.props.headers) {
                    text += cc.props.headers.join(',') + '\n'
                }
                if (cc.props.rows) {
                    cc.props.rows.forEach(r => text += Array.isArray(r) ? r.join(',') : Object.values(r) + '\n');
                }
            });
        }

        return text;
    }

    const copyOutputTxt = () => {
        let text = run.props.name + '\n';
        run.children.forEach(c => {
            text = getTextFromSection(c, text)
        });
        navigator.clipboard.writeText(text.replaceAll('<br>', '\n').replaceAll('<br/>', '\n'));
    }

    const removeOutput = () => {
        const currentOutputs = globalState.widgetOutputs;
        currentOutputs.splice(index, 1);
        setGlobalState({...globalState, widgetOutputs: [...currentOutputs]});
        localStorage.setItem("WIDGET_OUTPUTS", JSON.stringify(currentOutputs));
    }

    return (
        <div ref={ref} style={{opacity: run.old && !hideButtons ? '0.9' : ''}}>
            {run.type && run.type.toLowerCase() === 'widget' && run.children && run.children.length > 0 && (
                <div style={{border: '1px solid #757474'}}>
                    <WidgetListItemWrapper style={{
                        margin: 0,
                        padding: 5,
                        backgroundColor: '#835811',
                        fontSize: 20,
                        filter: run.old ? 'saturate(0.5)' : ''
                    }}>
                        <div className="title" style={{fontWeight: 600, color: "white"}}>
                            {run.props.name}
                            <span style={{position: "absolute", right: 5, top: 3}}>
                                {!hideButtons && <>
                                    <Button icon={<CopyOutlined/>} style={{margin: 5}} size={"small"}
                                            onClick={copyOutputTxt}> Text </Button>
                                    {typeof ClipboardItem !== 'undefined' && <Button icon={<CopyOutlined/>} size={"small"}
                                             onClick={copyOutputImg}> Image </Button>}
                                    <Button type={"text"} icon={<CloseOutlined/>}
                                            onClick={removeOutput}/>
                                </>}
                                {hideButtons && <>
                                    <img src="/logo.png" width="100px" alt="vortimo-logo"/>
                                </>}
                            </span>
                        </div>
                    </WidgetListItemWrapper>

                    {run.children.filter(c => c).map(c => (
                        <WidgetOutputItem run={c} globalState={globalState} index={index}
                                          setGlobalState={setGlobalState}/>
                    ))}
                </div>)
            }
            {run.type.toLowerCase() === 'section' && <Section globalState={globalState} setGlobalState={setGlobalState} data={run}>
                {run.children && run.children.length > 0 && run.children.filter(c => c).map(c => (
                    <WidgetOutputItem run={c} globalState={globalState} index={index}
                                      setGlobalState={setGlobalState}/>))}
            </Section>}
            {run.type.toLowerCase() === 'table' &&
            <OutputTable data={run} globalState={globalState} setGlobalState={setGlobalState}/>}
            {run.type.toLowerCase() === 'propertytable' &&
            <PropertyOutputTable data={run} globalState={globalState} setGlobalState={setGlobalState}/>}
            {run.type.toLowerCase() === 'widgeterror' &&
            <WidgetError title={run.props.title} description={run.props.description}/>}

        </div>
    );
}

export default WidgetOutputItem;
